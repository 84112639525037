<template>
    <div class="w-100 pl-4 mb-4">
        <v-card outlined>
            <v-card-text>
                <p class="display-1 text--primary" v-html="$t('clients.heading')"></p>
                <p v-html="$tc('clients.subheading', clients.length, { memberid: getActingMemberid })"></p>
            </v-card-text>
            <v-row>
            </v-row>
            <v-row>
                <v-col class="pl-7 pb-4 pr-6">
                    <div v-for="(client, clientIndex) in clients" :key="`client-${clientIndex}`">
                        <v-card class="elevation-2 mb-4" max-width="650">
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <p class="text-h4 text--primary">
                                            {{ `${ client.mandant_vorname } ${ client.mandant_name }` }}
                                        </p>
                                        <v-divider/>
                                        <p class="text--primary mt-4">
                                            {{ client.mandant_firma }}
                                        </p>
                                        <a
                                            class="text-decoration-underline"
                                            :href="`https://www.google.com/maps/place/${client.mandant_str.replace(' ', '+')},+${client.mandant_plz}+${client.mandant_ort}`"
                                            target="_blank"
                                            rel="noopener"
                                        >
                                            {{ `${ client.mandant_str }, ${ client.mandant_plz } ${ client.mandant_ort }` }}
                                        </a>
                                        <div class="text--primary ">
                                            <div
                                                v-for="(detailListItem, index) in detailListItems"
                                                :key="`detailListItemIndex-${index}`"
                                                class="mt-4"
                                            >
                                                <b>{{ detailListItem.key }}:</b> {{ client[detailListItem.value] }}
                                            </div>
                                            <div
                                                v-for="(privacyAndAgreement, index) in privacyAndAgreementLinks"
                                                :key="`privacyIndex${index}`"
                                                :class="(index === 0 ? 'mt-4' : '')"
                                            >
                                                <b>{{ privacyAndAgreement.label }}: </b>
                                                <a
                                                    class="text-decoration-underline"
                                                    :href="privacyAndAgreement.uri(client)"
                                                    target="_blank"
                                                    rel="noopener"
                                                >
                                                    {{ privacyAndAgreement.uri(client) }}
                                                </a>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MembersService from '@/services/members.service'

export default {
    name: 'Clients',
    metaInfo: {
        title: 'Clients',
    },
    data: function () {
        return {
            clients: [],
        }
    },
    computed: {
        ...mapGetters([
            'getActingMemberid',
        ]),
        detailListItems: function () {
            return [
                {
                    key: this.$i18n.t('common.labels.vatTaxId'),
                    value: `mandant_steuernr`,
                },
                {
                    key: this.$i18n.t('common.labels.phone'),
                    value: `mandant_tel`,
                },
                {
                    key: this.$i18n.t('common.labels.mail'),
                    value: `mandant_mail`,
                },
                {
                    key: this.$i18n.t('common.labels.web'),
                    value: `mandant_internet`,
                },
            ]

        },
        privacyAndAgreementLinks() {
            return [
                {
                    label: this.$i18n.t('common.labels.privacy'),
                    uri(client) {
                        return client.mandant_datenschutz
                    },
                },
                {
                    label: this.$i18n.t('common.labels.impress'),
                    uri(client) {
                        return client.mandant_impressum
                    },
                },
            ]
        }
    },
    async created() {
        await this.$store.dispatch('setLoaders', {clients: true})
        this.clients = await MembersService.getClients({
            memberid: this.getActingMemberid,
        })
        await this.$store.dispatch('setLoaders', {clients: false})
    },
}
</script>
